export const // uploaderActionTypes
  UPLOAD_TRACKS = 'upload_trackS',
  UPLOAD_SUCCESS = 'upload_success',
  UPLOAD_FAILURE = 'upload_failure',
  // trackListActiontypes
  LOAD_LIBRARY = 'load_library',
  LOAD_LIBRARY_SUCCESS = 'load_library_success',
  LOAD_LIBRARY_FAILURE = 'load_library_failure',
  FETCH_TRACKS = 'fetch_tracks',
  FETCH_TRACKS_SUCCESS = 'fetch_tracks_success',
  FETCH_TRACKS_FAILURE = 'fetch_tracks_failure',
  SELECT_TRACK = 'select_track',
  TOGGLE_EDIT_MODE = 'toggle_edit_mode',
  POST_TRACK_DATA = 'poat_track_data',
  POST_TRACK_DATA_SUCCESS = 'post_track_data_success',
  POST_TRACK_DATA_FAILURE = 'post_track_data_failure',
  DELETE_TRACK = 'delete_track',
  DELETE_TRACK_CONFIRM = 'delete_track_confirm',
  DELETE_TRACK_CANCEL = 'delete_track_cancel',
  DELETE_TRACK_SUCCESS = 'delete_track_success',
  DELETE_TRACK_FAILURE = 'delete_track_failure',
  ORDER_TRACKS_BY_FIELD_VALUE = 'order_tracks_by_field_value',
  FETCH_DETAIL_VIEW = 'fetsch_detail_view',
  FETCH_DETAIL_VIEW_FAILURE = 'fetch_detail_view_failure',
  FETCH_DETAIL_VIEW_SUCCESS = 'fetch_detail_view_success',
  CLOSE_DETAIL_VIEW = 'close_detail_view',
  DISMISS_LIBRARY_ERR = 'dismiss_library_err',
  TOGGLE_SEARCH = 'toggle_search',
  SEARCH_LIBRARY = 'search_library',
  // playerActiontypes
  PLAY_TRACK = 'play_track',
  PAUSE_TRACK = 'pause_track',
  STOP_TRACK = 'stop_track',
  START_NEW_QUEUE = 'start_new_queue',
  UNSHIFT_TO_QUEUE = 'unshift_to_queue',
  ADD_TRACK_TO_QUEUE = 'add_track_to_queue',
  PLAY_NEXT = 'play_next',
  PLAY_PREV = 'play_prev',
  PLAY_FROM_QUEUE = 'play_from_queue',
  SEEK = 'seek',
  // messageActiontypes
  SET_MESSAGE = 'set_message',
  CLEAR_MESSAGE = 'clear_message',
  SET_ALERT = 'set_alert',
  CLEAR_ALERT = 'clear_alert',
  // authActiontypes
  CHECK_USER_AGENT = 'check_user_agent',
  POST_REGISTRATION = 'post_registration',
  REGISTRATION_SUCCESS = 'registration_success',
  REGISTRATION_FAILURE = 'registration_failure',
  POST_LOGIN = 'post_login',
  LOGIN_SUCCESS = 'login_success',
  LOGIN_FAILURE = 'login_failure',
  UNAUTH_USER = 'unauth_user',
  GET_USER_INFO = 'get_user_info',
  GET_USER_INFO_SUCCESS = 'get_user_info_success',
  GET_USER_INFO_FAILURE = 'get_user_info_failure',
  TRIGGER_ERROR_MESSAGE = 'trigger_error_message',
  CLEAR_ERROR = 'clear_error',
  INIT_GDRIVE_CONNECT = 'init_gdrive_connect',
  INIT_GDRIVE_CONNECT_SUCCESS = 'init_gdrive_connect_success',
  INIT_GDRIVE_CONNECT_FAILURE = 'init_gdrive_connect_failure';
